import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-does-journey-planner-v3-actually-work"
    }}>{`How does Journey Planner v3 actually work?`}</h1>
    <p>{`Journey Planner v3 is a service for finding travel options for public transport and mobility for all of Norway. It serves as a backend service for most journey planning apps within the public transport sector in Norway today. However, how does it actually work? This article aims to provide an overview of how this service works and what makes it return travel options for your search.`}</p>
    <p><strong parentName="p">{`Using data from multiple sources`}</strong></p>
    <p>{`To enable multi-modal travel options several datasets are fed into the search engine.`}</p>
    <span style={{
      "textDecoration": "underline"
    }}>Static dataset:</span>
    <ul>
      <li parentName="ul">{`OpenStreetMap dataset for all of Norway (`}<a parentName="li" {...{
          "href": "http://www.geofabrik.de/"
        }}>{`geofabrik.de`}</a>{`)`}</li>
      <li parentName="ul">{`Elevation data for all of Norway (`}<a parentName="li" {...{
          "href": "https://www.norgeskart.no/"
        }}>{`kartverket.no`}</a>{`)`}</li>
      <li parentName="ul">{`All Norwegian Stop Places for public transport (`}<a parentName="li" {...{
          "href": "https://entur.no/"
        }}>{`entur.no`}</a>{`)`}</li>
      <li parentName="ul">{`All Timetable data for all Norwegian public transport services (`}<a parentName="li" {...{
          "href": "https://entur.no/"
        }}>{`entur.no`}</a>{`)`}</li>
    </ul>
    <span style={{
      "textDecoration": "underline"
    }}>Dynamic dataset:</span>
    <ul>
      <li parentName="ul">{`All real-time feeds for Norwegian public transport service (`}<a parentName="li" {...{
          "href": "https://entur.no/"
        }}>{`entur.no`}</a>{`)`}</li>
      <li parentName="ul">{`All available feeds of shared mobility (`}<a parentName="li" {...{
          "href": "https://entur.no/"
        }}>{`entur.no`}</a>{`)`}</li>
    </ul>
    <p>{`All datasets and feeds are fed into the search engine which continuously holds an instant representation of the entire public transport network.`}</p>
    <p><strong parentName="p">{`The algorithm for finding the optimal path`}</strong></p>
    <p>{`Any query for a journey between A and B will run the full search algorithm on the current representation. The search algorithm uses the RAPTOR (`}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-us/research/wp-content/uploads/2012/01/raptor_alenex.pdf"
      }}>{`https://www.microsoft.com/en-us/research/wp-content/uploads/2012/01/raptor_alenex.pdf`}</a>{`) algorithm to find relevant journeys. Broken down, this means the algorithm checks for each minute from the requested search time and looks for pareto-optimal results. The current implementation checks for pareto-optimality on the following factors:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Arrival time`}</em>{` - earliest possible arrival is optimal`}</li>
      <li parentName="ul"><em parentName="li">{`Departure time`}</em>{` - latest possible departure is optimal`}</li>
      <li parentName="ul"><em parentName="li">{`Transfers`}</em>{` - fewer is optimal`}</li>
      <li parentName="ul"><em parentName="li">{`Cost`}</em>{` - accumulated value for all elements of a journey; walk paths, transfers, waiting time, time on public transit vehicle, and boarding/alighting a vehicle. Lowest value is optimal`}</li>
    </ul>
    <p>{`Given the above factors, a journey is returned as an option only if the journey is optimal with respect to one of these factors. Additionally, some journeys are filtered out of the returned sets of journeys to avoid obviously undesirable alternatives.`}</p>
    <p><strong parentName="p">{`Neutrality`}</strong></p>
    <p>{`The nature of journey planning is complex and what is considered the “best” option is subjective to the preferences of the end user. The system is designed to try to find a sweet spot for representing travel options as neutral as possible while still providing relevant options to the user. Basing this on calculation of finding pareto optimal paths, given the factors described above, is not perfect, however provides the best collection of travel options in most cases within a reasonable response time. Additionally, the API is rich with parameters to tune your query to achieve desired result, however this is not the default behavior of the service.`}</p>
    <p><strong parentName="p">{`NOTE:`}</strong>{` This document tries to describe a high level description of how the service works. If you want the full details of exactly how the algorithm works, the search engine is open source and you can find the full source code at `}<a parentName="p" {...{
        "href": "http://www.github.com/opentripplanner"
      }}>{`www.github.com/opentripplanner`}</a>{`. There are multiple deployments of this search engine around the word and additional information can be found on the project webpage - `}<a parentName="p" {...{
        "href": "www.opentripplanner.org"
      }}>{`www.opentripplanner.org`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      